// src/AgeCalculator.js
import React, { useState, useEffect } from 'react';

const AgeCalculator = ({ birthDate }) => {
  const [age, setAge] = useState(calculateAge(birthDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setAge(calculateAge(birthDate));
    }, 1000); // Update every 1 second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [birthDate]);

  function calculateAge(birthDate) {
    const now = new Date();
    const birth = new Date(birthDate);

    let years = now.getFullYear() - birth.getFullYear();
    let months = now.getMonth() - birth.getMonth();
    let days = now.getDate() - birth.getDate();

    if (days < 0) {
      months--;
      days += new Date(now.getFullYear(), now.getMonth(), 0).getDate(); // Days in the previous month
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    const seconds = Math.floor((now - birth) / 1000) % 60;
    const minutes = Math.floor((now - birth) / 1000 / 60) % 60;
    const hours = Math.floor((now - birth) / 1000 / 3600) % 24;

    return {
      years,
      months,
      days,
      hours,
      minutes,
      seconds
    };
  }

  return (
    <> {age.years} years, {age.months} months, {age.days} days, {age.hours} hours, {age.minutes} minutes, {age.seconds} seconds </>
  );
};

export default AgeCalculator;
