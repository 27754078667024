import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://tanzilapi.alevelservice.com/api/',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
    }
});

export default instance;
