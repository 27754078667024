// src/CustomSlider.js
import React, { useState, useEffect } from 'react';
import './CustomSlider.css'; // Add custom styling

const reviews = [
  {
    name: "John Doe",
    review: "This product is amazing! It has changed my life for the better.",
    rating: 5,
    reviewByimage: "/images/review/tanzil-dewan-review1.jpg" // Path to image in public folder
  },
  {
    name: "Jane Smith",
    review: "A great value for the price. Highly recommend!",
    rating: 4,
    reviewByimage: "/images/review/tanzil-dewan-review2.jpg"
  },
  {
    name: "Alice Johnson",
    review: "Not what I expected. The quality could be better.",
    rating: 3,
    reviewByimage: "/images/review/tanzil-dewan-review3.jpg"
  },
  {
    name: "Bob Brown",
    review: "Exceptional service and quality. Will buy again.",
    rating: 5,
    reviewByimage: "/images/review/tanzil-dewan-review4.jpg"
  },
  {
    name: "Carol White",
    review: "It’s okay, but I expected more based on the reviews.",
    rating: 3,
    reviewByimage: "/images/review/tanzil-dewan-review5.jpg"
  },
  {
    name: "David Green",
    review: "Wonderful experience. Exceeded my expectations!",
    rating: 5,
    reviewByimage: "/images/review/tanzil-dewan-review6.jpg"
  },
  {
    name: "Eve Black",
    review: "Good product but arrived a bit late.",
    rating: 4,
    reviewByimage: "/images/review/tanzil-dewan-review7.jpg"
  },
  {
    name: "Frank Blue",
    review: "Average product. Nothing special.",
    rating: 2,
    reviewByimage: "/images/review/tanzil-dewan-review8.jpg"
  },
  {
    name: "Grace Grey",
    review: "Highly satisfied with the quality and service.",
    rating: 5,
    reviewByimage: "/images/review/tanzil-dewan-review9.jpg"
  },
  {
    name: "Hank Red",
    review: "The product did not meet my expectations.",
    rating: 2,
    reviewByimage: "/images/review/tanzil-dewan-review10.jpg"
  }
];

const CustomSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  // Calculate the indices of the reviews to display
  const displayIndices = [
    (currentIndex - 1 + reviews.length) % reviews.length,
    currentIndex,
    (currentIndex + 1) % reviews.length
  ];

  return (
    <div className="slider-container">
      <div className="slider">
        {displayIndices.map((index, position) => (
          <div
            key={index}
            className={`slide ${position === 1 ? 'active' : ''}`}
          >
            {reviews[index].reviewByimage ? <img src={reviews[index].reviewByimage} alt={`${reviews[index].name}'s review`} className="review-image" /> : <div className="review-content">
              <h3>{reviews[index].name}</h3>
              <p>{reviews[index].review}</p>
              <span className="rating">Rating: {reviews[index].rating} / 5</span>
            </div>}
             

            


          </div>
        ))}
      </div>
      <div className="dots">
        {reviews.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default CustomSlider;
